function modalShowHide(idModal) {
  const modalActive = document.querySelector('.modal-default-active');

  if (modalActive && modalActive.getAttribute('id') !== idModal) {
    modalActive.classList.remove('modal-default-active');
    document.body.classList.remove('no-scroll');
  }

  let modal = document.getElementById(idModal);
  if (modal) {
    modal.classList.toggle('modal-default-active');
    if (modal.classList.contains('modal-default-active')) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
}


function showHide(idModal) {
  let modal = document.getElementById(idModal);

  if (modal) {
    modal.classList.toggle('modal-default-active');
    if (modal.classList.contains('modal-default-active')) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
}


export default {
  showHideModal(context, idModal) {
    modalShowHide(idModal);
  },
  modalToggle(context, idModal) {
    showHide(idModal)
  },
  setFundsWallet(context, payload) {
    context.commit("RESET_CHECKOUT");
    context.commit('SET_USER_IDENTIFIER', true);
    context.commit("SET_CART", payload.cart);
    context.commit("CHECKOUT_PARAMS", { customer: payload.customer });
    modalShowHide("modal-zicket-resume");
  },
  startCheckout(context, { cart, event }) {
    context.commit("CONCLUDED_CHECKOUT", false)
    context.commit("SET_EVENT", event);
    if (context.getters.hasUserIdentifier) {
      context.commit("SET_CART", cart)
      modalShowHide("modal-zicket-resume");
    } else {
      context.commit("RESET_CHECKOUT");
      context.commit("SET_CART", cart)
      modalShowHide("modal-zicket-mobile");
    }
  },
  setLocale(context, value) {
    context.commit("setLocale", value);
    localStorage.setItem("zicket-locale", value);
  },
  setUserIdentifier(context, value) {
    context.commit('SET_USER_IDENTIFIER', value);
  },
  setOrder(context, value) {
    context.commit("SET_ORDER", value)
  },
  setUserToken(context, value) {
    context.commit("SET_TOKEN", value)
  },
  setCheckoutParams(context, payload) {
    context.commit("CHECKOUT_PARAMS", payload);
  },
  setCheckoutCart(context, payload) {
    context.commit("SET_CART", payload)
  },
  addCheckoutCart(context, payload) {
    context.commit("ADD_CART_PARAMS", payload)
  },
  setConcludedCheckout(context, value) {
    context.commit("CONCLUDED_CHECKOUT", value);
  },
  setGooglePayData(context, value) {
    context.commit("GOOGLE_PAY_DATA", value);
  },
  setPaymentsConfig(context, value) {
    context.commit("PAYMENTS_CONFIG", value);
  },
  setApplePayData(context, value) {
    context.commit("APPLE_PAY_DATA", value);
  },

  updateCountryCode({ commit }, countryCode) {
    commit('SET_COUNTRY_CODE', countryCode);
  },

  updateMbwayCountryCode({ commit }, countryCode) {
    commit('SET_MBWAY_COUNTRY_CODE', countryCode);
  },
  updateMbwayMobile({ commit }, mobile) {
    commit('SET_MBWAY_MOBILE', mobile);
  },
}
